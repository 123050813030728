/* You can add global styles to this file, and also import other style files */

html, body {
  padding: 0;
  margin: 0;
  height: 400px;
  color: #000000;
  background: #f1f1f1;
}

.color-button {
  width: 14px;
  height: 14px;
  font-size: 0;
  margin: 2px;
  float: left;
  cursor: pointer;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #7E8299;
  --gray-dark: #3F4254;
  --primary: #F58634;
  --secondary: #E4E6EF;
  --success: #1BC5BD;
  --info: #8950FC;
  --warning: #F58634;
  --danger: #F64E60;
  --light: #F3F6F9;
  --dark: #181C32;
  --white: #ffffff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  /*background-color: #ffffff;*/
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #F58634;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #F58634;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #B5B5C3;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.logo-d7-size {
  max-height: 50px !important;
  margin-top: 0px;
}

.form-field-around {
  padding: 10px;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}

.alert-scheduler {
  color: #ffffff;
  background-color: #555753;
  border-color: white;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

button.action-steps-btn {
  padding: 5px 40px;
}

:host .mat-flat-button.mat-curinga, .mat-raised-button.mat-curinga, .mat-fab.mat-curinga, .mat-mini-fab.mat-curinga {
  background-color: #b9dfca;
}

:host .mat-flat-button.mat-curinga, .mat-raised-button.mat-curinga, .mat-fab.mat-curinga, .mat-mini-fab.mat-curinga {
  color: black;
}

:host .mat-flat-button.mat-verdeescuro, .mat-raised-button.mat-verdeescuro, .mat-fab.mat-verdeescuro, .mat-mini-fab.mat-verdeescuro {
  background-color: #00918a;
}

:host .mat-flat-button.mat-verdeescuro, .mat-raised-button.mat-verdeescuro, .mat-fab.mat-verdeescuro, .mat-mini-fab.mat-verdeescuro {
  color: white;
}

:host .mat-flat-button.mat-blackbutton, .mat-raised-button.mat-blackbutton, .mat-fab.mat-blackbutton, .mat-mini-fab.mat-blackbutton {
  background-color: #1b1b1b;
}

:host .mat-flat-button.mat-blackbutton, .mat-raised-button.mat-blackbutton, .mat-fab.mat-blackbutton, .mat-mini-fab.mat-blackbutton {
  color: white;
}


/*
🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥
🔥
🔥                  Novos Styles
🔥
🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥 🔥
*/
#telaLogin{
  position: fixed;
  top:0px;
  left:0px;
  width:100vw;
  height:100vh;
  background: linear-gradient(to bottom, #fff 40%, #ff9800);
}
.cardLogin{
  border-radius:5px;
  border:solid 1px #ddd;
  box-shadow: 0px 8px 10px rgba(0,0,0,0.5);
}
.btnLogin{
  width: calc(100% - 30px);
}
.btnVoltar{
  width: calc(100% - 30px);
  margin-top: 15px;
}
.page-title{
  color: #000;
  font-size:20px !important;
  font-weight:bold !important;
}

.second-title{
  color: #6d6d6d;
  font-size:18px !important;
}

.btn-warning{
  cursor: pointer;
  background:#ff9800;
  height: 30px !important;
  width:30px !important;
  padding:5px;
  color:#fff;
  border-radius:5px;
  margin-right:15px;
  font-size: 20px;
}

.btn-danger{
  cursor: pointer;
  background:#f44336;
  height: 30px !important;
  width:30px !important;
  padding:5px;
  color:#fff;
  border-radius:5px;
  margin-right:15px;
  font-size: 20px;
}

.btn-primary{
  cursor: pointer;
  background:#2196f3;
  height: 30px !important;
  width:30px !important;
  padding:5px;
  color:#fff;
  border-radius:5px;
  margin-right:15px;
  font-size: 20px;
}

.mat-header-cell{
  font-size: 16px !important;
  background-color: #ddd !important;
  color: #555 !important;
}
